const base = process.env.REACT_APP_BASEURL;
const dashboardUrl = "/cms";
const authUrl = "/auth";
const mailService = process.env.REACT_APP_API_URL + "/api/email";
const teamleaderService = process.env.REACT_APP_API_URL + "/api/teamleader";
const exactService = process.env.REACT_APP_API_URL + "/api/exact";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  products: dashboardUrl + "/products",
  productList: dashboardUrl + "/products/product-list",
  addProduct: dashboardUrl + "/products/add-product",
  editProduct: dashboardUrl + "/products/edit-product/",
  featuredProducts: dashboardUrl + "/products/featured",
  discounts: dashboardUrl + "/discounts/",
  promotions: dashboardUrl + "/discounts/promotions",
  discountCodes: dashboardUrl + "/discounts/discount-codes",
  ordersList: dashboardUrl + "/orders/orders",
  customerList: dashboardUrl + "/orders/customers",
  users: dashboardUrl + "/admin/users",
  reports: dashboardUrl + "/reports",
  order_invoice: mailService + "/order/invoice",
  teamleaderOrder: teamleaderService + "/order",
  exactOrder: exactService + "/order",
  exactCustomers: exactService + "/customers",
  exactProducts: exactService + "/items",
  resend_confirmation_email: mailService + "/order/confirmed/resend",
};
